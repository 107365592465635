import { ReactComponentPropsBase } from "../../../base-props/ReactComponentPropsBase";

import { DynamicBlock } from "SiteComponents";
import * as Components from "SiteComponents";
import { IReactComponentProps } from "src/base-props/IReactComponentProps";
import { DynamicBlockProps } from "components/utils/DynamicBlock/DynamicBlock";
import LayoutBlockGridPropsProxy, { GridProps } from "./LayoutBlockLayoutProps";
import GridContainer, { GridContainerStyleProps } from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";

export interface Blocks extends DynamicBlockProps {
  gridItemProps?: any;
}
export interface LayoutBlockProps extends ReactComponentPropsBase {
  componentName: keyof typeof Components;
  component?: IReactComponentProps;
  additionalGridContainerProps?: GridContainerStyleProps;
  noGrid?: boolean;
}

const LayoutBlock: React.FC<LayoutBlockProps> = ({
  component,
  componentName,
  additionalGridContainerProps,
  noGrid,
}) => {
  const gridProps: GridProps | undefined =
    LayoutBlockGridPropsProxy[componentName];

  if (!gridProps || !component) return null;

  const { gridContainerProps, gridItemProps } = gridProps;

  if (noGrid) {
    return <DynamicBlock component={component} componentName={componentName} />
  }

  return (
    <GridContainer {...gridContainerProps} {...additionalGridContainerProps}>
      <GridItem {...gridItemProps}>
        <DynamicBlock component={component} componentName={componentName} />
      </GridItem>
    </GridContainer>
  );
};

export default LayoutBlock;
