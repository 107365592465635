import styles from "./ReportPage.module.scss";

import ContentArea, {
  ContentAreaProps,
} from "components/utils/ContentArea/ContentArea";
import ReportPageHeroBlock from "components/blocks/ReportPageHeroBlock/ReportPageHeroBlock";
import { ReportPageHeroBlockProps } from "components/blocks/ReportPageHeroBlock/ReportPageHeroBlock";
import TopPageBlocks, {
  TopPageBlocksProps,
} from "components/shared/TopPageBlocks/TopPageBlocks";
import CardListBlock, {
  CardListBlockProps,
} from "components/blocks/CardListBlock/CardListBlock";
import { BlockContainer, MultiColumn } from "SiteComponents";
import ContactBlock, {
  ContactBlockProps,
} from "components/blocks/ContactBlock/ContactBlock";

export interface ReportPageProps extends TopPageBlocksProps {
  contentArea: ContentAreaProps;
  contactBlock?: ContactBlockProps;
  hero: ReportPageHeroBlockProps;
  reportChapters?: CardListBlockProps;
  relatedReports?: CardListBlockProps;
}

const ReportPage: React.FC<ReportPageProps> = ({
  contentArea,
  contactBlock,
  hero,
  alert,
  mainIntro,
  reportChapters,
  relatedReports,
}) => {
  return (
    <main className={styles.reportPage}>
      {hero && <ReportPageHeroBlock {...hero} />}
      <MultiColumn>
        {(alert || mainIntro) && (
          <MultiColumn.Left
            gridStyle={{ lgOffset: 2, lg: 6 }}
            nestedGridStyle={{ lg: 12, xl: 12, lgOffset: 0 }}
          >
            <TopPageBlocks alert={alert} mainIntro={mainIntro} />
          </MultiColumn.Left>
        )}

        {contactBlock && (
          <MultiColumn.Right>
            <BlockContainer horizontalSpacing="md">
              <ContactBlock {...contactBlock} />
            </BlockContainer>
          </MultiColumn.Right>
        )}

        {(contentArea || relatedReports) && (
          <MultiColumn.Left>
            {contentArea && <ContentArea {...contentArea} />}
          </MultiColumn.Left>
        )}
        {reportChapters && reportChapters.listItems && (
          <MultiColumn.Left
            gridStyle={{ lgOffset: 2, lg: 6 }}
            nestedGridStyle={{ lg: 12, xl: 12, lgOffset: 0 }}
          >
            <CardListBlock {...reportChapters} />
          </MultiColumn.Left>
        )}
      </MultiColumn>

      {relatedReports && (
        <BlockContainer>
          <CardListBlock {...relatedReports} />
        </BlockContainer>
      )}
    </main>
  );
};

export default ReportPage;
