import classNames from "classnames";
import styles from "./Tag.module.scss";

export interface TagProps {
  text: string;
  className?: string;
  onDark?: boolean;
  onClick?: () => void;
}

const Tag = ({ text, className, onDark = false, onClick }: TagProps) => {
  return (
    <div
      onClick={onClick}
      title={text}
      className={classNames(styles.tag, className, {
        [styles.onDark]: onDark,
      })}
    >
      {text}
    </div>
  );
};

export default Tag;
