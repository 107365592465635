import React from "react";
import styles from "./SearchPage.module.scss";
import HeroBlock, {
  HeroBlockProps,
} from "components/blocks/HeroBlock/HeroBlock";
import { ReactComponentPropsBase } from "src/base-props/ReactComponentPropsBase";
import SearchBlock, {
  SearchBlockProps,
} from "components/blocks/SearchBlock/SearchBlock";

export interface SearchPageProps extends ReactComponentPropsBase {
  hero?: HeroBlockProps;
  search?: SearchBlockProps;
}
//TODO: Obsolete legacy search. Should be removed after all sites have moved to the new v2 page templates.
const SearchPage: React.FC<SearchPageProps> = ({ hero, search }) => {
  return (
    <main className={styles.searchPage}>
      {hero && <HeroBlock {...hero} />}
      {search && <SearchBlock {...search} />}
    </main>
  );
};

export default SearchPage;
