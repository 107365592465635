import React from "react";
import Media from "../../utils/Media/Media";
import styles from "./MultiMediaBlock.module.scss";
import classNames from "classnames";
import { ImageProps } from "components/base/Image/Image";
import { VideoProps } from "components/shared/Video/Video";
import Truncate from "components/base/Truncate/Truncate";

export interface MultiMediaBlockProps {
  video?: VideoProps;
  image?: ImageProps;
  byline?: string;
  description?: string;
  expandButtonText?: string;
  closeButtonText?: string;
  isLargeMedia?: boolean;
  isSmallMedia?: boolean;
  onDarkBackground?: boolean;
}

const MultiMediaBlock: React.FC<MultiMediaBlockProps> = ({
  video,
  image,
  byline,
  description,
  expandButtonText,
  closeButtonText,
  onDarkBackground,
  isSmallMedia,
}) => {
  return (
    <div
      className={classNames(styles.multiMediaBlock, {
        [styles.dark]: onDarkBackground === true,
        [styles.small]: isSmallMedia
      })}
    >
      {(video || image) && (
        <Media className={styles.media} video={video} image={image} />
      )}
      {expandButtonText && closeButtonText ? (
        <Truncate
          truncateText={closeButtonText}
          expandText={expandButtonText}
          className={styles.imageTextContainer}
        >
          <div className={classNames(styles.imageText, {})}>
            {description && <p className={styles.description}>{description}</p>}
            {byline && <p className={styles.byline}>{byline}</p>}
          </div>
        </Truncate>
      ) : (
        <div className={classNames(styles.imageText, {})}>
          {description && <p className={styles.description}>{description}</p>}
          {byline && <p className={styles.byline}>{byline}</p>}
        </div>
      )}
    </div>
  );
};

export default MultiMediaBlock;
