import { useEffect } from "react";
import Image, { ImageProps } from "components/base/Image/Image";
import style from "./SearchResults.module.scss";
import { ReactComponentPropsBase } from "../../../../base-props/ReactComponentPropsBase";
import Button from "components/base/Button/Button";
import styles from "./SearchResults.module.scss";

export interface Results {
  heading?: string;
  ingress?: string;
  url?: string;
  image?: ImageProps;
}

export interface SearchResultsProps extends ReactComponentPropsBase {
  results?: Results[];
  loadMoreText?: string;
  loadMore?: () => void;
  showLoadMore?: boolean;
  errorMessage?: string;
  query?: string;
  queryId?: string;
  pageNumber?: string;
  totalCount?: number;
  bannerIds?: string;
  referralUrl?: string;
  queryLog?: string;
}
//TODO: Obsolete legacy search. Should be removed after all sites have moved to the new v2 page templates.
const SearchResults: React.FC<SearchResultsProps> = ({
  results,
  loadMoreText,
  loadMore,
  showLoadMore,
  errorMessage,
  query,
  queryId,
  pageNumber,
  totalCount,
  bannerIds,
  referralUrl,
  queryLog,
}) => {
  useEffect(() => {
    // CludoSearch is loaded in by a script tag in head in backend,
    // need to ignore error for typescript to accept it
    //@ts-ignore
    if (typeof CludoSearch === "undefined") return;
    //@ts-ignore
    CludoSearch.trackClicks(
      queryLog, // Returned from trackQuery function
      ".search-results__result-item"
    );
  }, []);

  const cludoAttributes = queryId
    ? {
        "data-cludo-query": query,
        "data-cludo-query-id": queryId,
        "data-cludo-cludo-page": pageNumber,
        "data-cludo-total-results": totalCount?.toString(),
        "data-cludo-banner-ids": bannerIds,
        "data-cludo-referral-url": referralUrl,
      }
    : {};

  return (
    <div className={style.searchResults} {...cludoAttributes}>
      {errorMessage && (
        <div className={styles.errorMessage}>{errorMessage}</div>
      )}
      <ul>
        {results?.map(({ heading, ingress, url, image }, index) => (
          <li
            key={heading || "" + index}
            className="search-results__result-item"
            data-cludo-search-result={heading}
          >
            <a href={url}>
              <h3>{heading}</h3>
            </a>
            {ingress && <p dangerouslySetInnerHTML={{ __html: ingress }} />}
            {image && (
              <Image
                className={styles.image}
                {...image}
                sizes={{
                  sm: 330,
                  md: 520,
                  lg: 420,
                  xl: 600,
                }}
              />
            )}
          </li>
        ))}
      </ul>
      {showLoadMore && (
        <Button onClick={loadMore} theme="secondary">
          {loadMoreText}
        </Button>
      )}
    </div>
  );
};

export default SearchResults;
