interface RegionSearchResult {
  label: string;
  title: string;
  display_name: string;
}

export const getRegions = async (search: string): Promise<RegionSearchResult[]> => {
  return await fetch(`https://app-simapi-prod.azurewebsites.net/place/${search}`)
  .then(res => res.json())
  .then(res => res as RegionSearchResult[]);
}

export const getAllRegions = async (): Promise<RegionSearchResult[]> => {
  return await fetch("https://app-simapi-prod.azurewebsites.net/placelist")
  .then(res => res.json())
  .then(res => res as RegionSearchResult[]);
}