import React, { useState } from "react";
import styles from "./EventPage.module.scss";

import EventPageHeroBlock, {
  EventPageHeroBlockProps,
} from "components/blocks/EventPageHeroBlock/EventPageHeroBlock";
import { HighlightedInfoBlockProps } from "components/blocks/HighlightedInfoBlock/HighlightedInfoBlock";
import ContentArea, {
  ContentAreaProps,
} from "components/utils/ContentArea/ContentArea";
import ContactBlock, {
  ContactBlockProps,
} from "components/blocks/ContactBlock/ContactBlock";
import {
  BlockContainer,
  CardListBlock,
  MultiColumn,
  TopPageBlocks,
  Tabs,
} from "SiteComponents";
import { CardListBlockProps } from "components/blocks/CardListBlock/CardListBlock";
import { TabsProps } from "components/utils/Tabs/Tabs";
import ProgramBlock, {
  ProgramBlockProps,
} from "components/blocks/ProgramBlock/ProgramBlock";
export interface EventPageProps {
  contentArea?: ContentAreaProps;
  mainIntro?: string;
  highlightedInfo?: HighlightedInfoBlockProps;
  hero: EventPageHeroBlockProps;
  relatedEvents?: CardListBlockProps;
  contributors?: ContactBlockProps;
  program?: ProgramBlockProps;
  contactBlock?: ContactBlockProps;
  tabs: TabsProps;
}

const EventPage: React.FC<EventPageProps> = ({
  contentArea,
  hero,
  mainIntro,
  highlightedInfo,
  program,
  contactBlock,
  relatedEvents,
  contributors,
  tabs,
}) => {
  const blocksWidth = { xs: 12, lg: 6, lgOffset: 3 };

  const [activePanel, setActivePanel] = useState<{
    panelComponent: React.FC<any>;
    panelComponenProps: any;
  }>();

  const handleTabChange = (id: string) => {
    switch (id) {
      case "program":
        setActivePanel({
          panelComponent: ProgramBlock,
          panelComponenProps: program,
        });
        break;
      case "contributors":
        setActivePanel({
          panelComponent: ContactBlock,
          panelComponenProps: contributors,
        });
        break;
      case "contentArea":
        setActivePanel({
          panelComponent: ContentArea,
          panelComponenProps: contentArea,
        });
        break;
    }
  };

  return (
    <main className={styles.eventPage}>
      {hero && <EventPageHeroBlock {...hero} />}
      <MultiColumn>
        {(mainIntro || highlightedInfo) && (
          <MultiColumn.Left
            gridStyle={{ lgOffset: 2, lg: 6 }}
            nestedGridStyle={{ lg: 12, xl: 12, lgOffset: 0 }}
          >
            <TopPageBlocks
              mainIntro={mainIntro}
              highlightedInfo={highlightedInfo}
              noMargin={true}
            />
          </MultiColumn.Left>
        )}

        <MultiColumn.Right>
          <BlockContainer rowGap="md" horizontalSpacing="md">
            {contactBlock && <ContactBlock {...contactBlock} />}
          </BlockContainer>
        </MultiColumn.Right>

        <MultiColumn.Left
          gridStyle={{ lgOffset: 2, lg: 6 }}
          nestedGridStyle={{ lg: 12, xl: 12, lgOffset: 0 }}
        >
          {tabs && (
            <div className={styles.tabContainer}>
              <Tabs
                {...tabs}
                onTabChange={handleTabChange}
                PanelComponent={
                  activePanel?.panelComponent
                    ? activePanel.panelComponent
                    : undefined
                }
                panelComponentProps={
                  activePanel?.panelComponenProps
                    ? activePanel.panelComponenProps
                    : undefined
                }
              />
            </div>
          )}
        </MultiColumn.Left>
      </MultiColumn>

      {relatedEvents && (
        <BlockContainer>
          <CardListBlock {...relatedEvents} />
        </BlockContainer>
      )}
    </main>
  );
};

export default EventPage;
