import React from "react";
import styles from "./ExamplePageHeroBlock.module.scss";
import classNames from "classnames";
import { ReactComponentPropsBase } from "../../../base-props/ReactComponentPropsBase";
import BlockContainer from "components/layout/BlockContainer/BlockContainer";
import MultiMediaBlock from "../MultiMediaBlock/MultiMediaBlock";
import IconMS from "src/components/utils/IconMS/IconMS";
import { ImageProps } from "../../base/Image/Image";
import { VideoProps } from "components/shared/Video/Video";
import useHeroBackground from "src/utils/use-hero-background/useHeroBackground";
import { useTheme } from "src/utils/useTheme";
import Tag, { TagProps } from "components/base/Tag/Tag";

export interface ExamplePageHeroBlockProps extends ReactComponentPropsBase {
  byline?: string;
  heading: string;
  image?: ImageProps;
  mediaText?: string;
  publishDate?: string;
  showLessText?: string;
  showMoreText?: string;
  subHeading?: string;
  mediaSize?: 'small' | 'medium' | 'large';
  videoLink?: VideoProps;
  tags?: TagProps[];
  tagListTitle?: string;
}

const ExamplePageHeroBlock: React.FC<ExamplePageHeroBlockProps> = ({
  byline,
  heading,
  image,
  mediaText,
  publishDate,
  showLessText,
  showMoreText,
  subHeading,
  mediaSize,
  videoLink,
  tags,
  tagListTitle,
}) => {
  const backgroundClass = useHeroBackground();
  const theme = useTheme();
  return (
    <div className={backgroundClass}>
      <BlockContainer
        className={classNames(styles.examplePageHeroBlock, {
          [styles.withMedia]: videoLink || image,
          [styles.largeMedia]: mediaSize === 'large',
          [styles.dark]: theme === "dark",
        })}
      >
        <div className={styles.examplePageHeroBlockText}>
          {subHeading && <p>{subHeading}</p>}
          {heading && <h1>{heading}</h1>}
          <div className={styles.tagsAndDate}>
          {tags && (
            <ul className={styles.tagList} title={tagListTitle}>
              {tags.map((tag, index) => (
                <li key={index}>
                  <Tag {...tag} className={styles.tag} onDark={theme === "dark"}/>
                </li>
              ))}
            </ul>
          )}
          {publishDate && (
            <span>
              <IconMS className={styles.icon} name="stylus" size="20px" />
              <p>{publishDate}</p>
            </span>
          )}

          </div>
        </div>
        {(videoLink || image) && (
          <div className={styles.examplePageHeroBlockMedia}>
            <MultiMediaBlock
              video={videoLink}
              image={image}
              description={mediaText}
              byline={byline}
              expandButtonText={showMoreText}
              closeButtonText={showLessText}
              isSmallMedia={mediaSize === 'small'} 
              onDarkBackground={theme === "dark"}
            />
          </div>
        )}
      </BlockContainer>
    </div>
  );
};

export default ExamplePageHeroBlock;
