import styles from "./AccordionBase.module.scss";
import { Accordion, AccordionButton, AccordionPanel } from "./Accordion";
import IconMS from "../../utils/IconMS/IconMS";
import classNames from "classnames";
import getId from "../../../utils/getId";

export interface AccordionBaseProps {
  closeLabel?: string;
  heading: string;
  openLabel?: string;
  children: React.ReactNode;
  isGlobal?: boolean;
  noBackground?: boolean;
  theme?: "primary" | "secondary";
  isInitiallyOpen?: boolean;
  small?: boolean;
}

const AccordionBase: React.FC<AccordionBaseProps> = ({
  closeLabel = "close",
  heading,
  openLabel = "open",
  children,
  isGlobal = false,
  theme = "primary",
  isInitiallyOpen = false,
  small,
}) => {
  const accordionId = getId(heading);

  return (
    <Accordion
      className={classNames(styles.accordionBase, {
        [styles[theme]]: theme,
        [styles.small]: small,
      })}
      isGlobal={isGlobal}
      theme={theme}
      isInitiallyOpen={isInitiallyOpen}
    >
      <AccordionButton
        className={classNames(styles.header)}
        {...{ closeLabel, openLabel, id: accordionId, isGlobal }}
      >
        {(isOpen: boolean) => (
          <>
            <IconMS
              name={expandIconName(theme, isOpen)}
              className={classNames(styles.icon, {
                [styles.isOpen]: isOpen,
              })}
            />
            <h3>{heading}</h3>
          </>
        )}
      </AccordionButton>
      <AccordionPanel className={styles.panel} id={accordionId}>
        {children}
      </AccordionPanel>
    </Accordion>
  );
};

function expandIconName(
  theme: "primary" | "secondary",
  isOpen: boolean
): string {
  if (theme === "primary") return "expand_more";
  if (theme === "secondary") return isOpen ? "remove" : "add";

  console.error("Invalid theme:", theme);
  return "expand_more";
}

export default AccordionBase;
