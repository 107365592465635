import React from "react";
import { Icon } from "SiteComponents";
import styles from "./EmptyState.module.scss";

interface EmptyStateProps {
  noHitsHeading: string;
  noHitsText: string;
}

const EmptyState = ({ noHitsHeading, noHitsText }: EmptyStateProps) => {
  return (
    <div className={styles.emptyState}>
      {noHitsHeading && <h3 className={styles.heading}>{noHitsHeading}</h3>}
      {noHitsText &&
        noHitsText
          .replace(".", ".;")
          .split(";")
          .map(text => <p  className={styles.text}>{text}</p>)}
      <Icon name="noResults" />
    </div>
  );
};

export default EmptyState;
