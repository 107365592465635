import { ContentAreaProps } from "components/utils/ContentArea/ContentArea";
import styles from "./StatisticsBlock.module.scss";
import { Button, IconMS, OverrideTheme, RichTextBlock } from "SiteComponents";
import { useState } from "react";
import classNames from "classnames";

export interface StatisticsBlockProps {
  regionCode: string;
  cardId: string;
  showAsGraph: boolean;
  hideFilters: boolean;
  hideTabs: boolean;
  description?: ContentAreaProps;
  displayName?: string;
  date?: string;
  source?: string;
  sourceText: string;
  aboutStatisticsText: string;
}

const StatisticsBlock = ({
  regionCode,
  cardId,
  showAsGraph,
  hideFilters,
  hideTabs,
  description,
  date,
  source,
  sourceText,
  aboutStatisticsText
}: StatisticsBlockProps) => {
  const scriptContent = JSON.stringify({
    regionCode: regionCode,
    cardId: cardId,
    isKPI: !showAsGraph,
    hideFilters: hideFilters,
    hideTabs: hideTabs,
  });
  const [expanded, setExpanded] = useState(false);

  return (
    <div>
      <div data-imdikator="embed" className={classNames(styles.statisticsBlock, {[styles.graph]: showAsGraph})}>
        <script
          type="application/json"
          dangerouslySetInnerHTML={{ __html: scriptContent }}
        />
      </div>
      <div className={styles.metaInfo}>
        {showAsGraph && source && date && (
          <p className={styles.source}>
            {sourceText}: {source}, {date} 
          </p> // Endre til å være som figurtekst
        )}
        {showAsGraph && description && description.items.length > 0 && (
          <>
          <OverrideTheme theme="light">
            <Button 
            theme="secondary"
            size="small"
              onClick={() => setExpanded(!expanded)}
            ><IconMS name="info" /> {aboutStatisticsText}</Button>
          </OverrideTheme>
            {expanded && <RichTextBlock text={description} />}
          </>
        )}
      </div>
    </div>
  );
};

export default StatisticsBlock;
