import React from "react";
import { ReactComponentPropsBase } from "../../../base-props/ReactComponentPropsBase";
import GridContainer from "components/layout/Grid/GridContainer";
import GridItem from "components/layout/Grid/GridItem";
import ContentAnchors, {
  ContentAnchorsProps,
} from "components/layout/ContentAnchors/ContentAnchors";
import AlertBlock, {
  AlertBlockProps,
} from "components/blocks/AlertBlock/AlertBlock";
import classNames from "classnames";
import styles from "./TopPageBlocks.module.scss";
import HighlightedInfoBlock, {
  HighlightedInfoBlockProps,
} from "components/blocks/HighlightedInfoBlock/HighlightedInfoBlock";
import AccordionItemBlock, { AccordionItemBlockProps } from "components/blocks/AccordionItemBlock/AccordionItemBlock";
import ContactBlockItem, { ContactBlockItemProps } from "components/blocks/ContactBlock/ContactBlockItem/ContactBlockItem";

export interface TopPageBlocksProps extends ReactComponentPropsBase {
  alert?: AlertBlockProps;
  mainIntro?: string;
  contentAnchors?: ContentAnchorsProps;
  className?: string;
  width?: "narrow" | "wide";
  highlightedInfo?: HighlightedInfoBlockProps;
  noMargin?: boolean;
  program?: AccordionItemBlockProps;
  contactBlockItems?: ContactBlockItemProps[];
}

const blockWidths = {
  narrow: { xs: 12, lg: 6, lgOffset: 3 },
  wide: { xs: 12 },
};

const TopPageBlocks: React.FC<TopPageBlocksProps> = ({
  alert,
  mainIntro,
  contentAnchors,
  className,
  width = "narrow",
  highlightedInfo,
  noMargin = false,
  program,
  contactBlockItems,
}) => {
  if (!alert && !mainIntro && !contentAnchors && !contactBlockItems) return null;

  return (
    <GridContainer
      horizontalSpacing="lg"
      className={classNames(styles.topPageBlocks, className, {
        [styles.noMargin]: noMargin,
      })}
    >
      {alert && (
        <GridItem {...blockWidths[width]}>
          <AlertBlock {...alert} />
        </GridItem>
      )}

      {mainIntro && (
        <GridItem {...blockWidths[width]}>
          <p className={styles.mainIntro}>{mainIntro}</p>
        </GridItem>
      )}
      {highlightedInfo && <HighlightedInfoBlock {...highlightedInfo} />}

      {contentAnchors && (
        <GridItem {...blockWidths[width]}>
          <ContentAnchors {...contentAnchors} />
        </GridItem>
      )}
      {contactBlockItems && contactBlockItems.length > 0 && (
        <GridItem {...blockWidths[width]}>
          <div className={styles.contactBlocks}>
            {contactBlockItems.map(block => <ContactBlockItem {...block}/>)}
          </div>
        </GridItem>
      )}
      {program && (
        <GridItem {...blockWidths[width]}>
          <AccordionItemBlock {...program}/>
        </GridItem>
      )}
    </GridContainer>
  );
};

export default TopPageBlocks;
