import styles from "./SearchField.module.scss";
import classNames from "classnames";
import { ReactComponentPropsBase } from "../../../base-props/ReactComponentPropsBase";
import Button from "../Button/Button";
import Icon from "components/utils/Icon/Icon";
import { useEffect, useRef, useState } from "react";
import IconMS from "src/components/utils/IconMS/IconMS";
import emitter from "src/utils/emitter";
import { useTheme } from "../../../utils/useTheme";
import Spinner from "../Spinner/Spinner";

export interface SearchFieldProps extends ReactComponentPropsBase {
  placeholder?: string;
  buttonLabel?: string;
  label?: string;
  search?: (query: string) => void;
  searchWord?: string;
  withIcon?: boolean;
  options?: { title: string; value: string }[];
  handleOptionClick?: (value: string) => void;
  isLoading?: boolean;
  wide?: boolean;
  withoutButton?: boolean;
}

const SearchField: React.FC<SearchFieldProps> = ({
  placeholder,
  buttonLabel,
  label,
  search,
  searchWord,
  withIcon,
  options,
  handleOptionClick,
  isLoading,
  wide,
  withoutButton,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [inputValue, setInputValue] = useState(searchWord || "");
  const [isOpen, setIsOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const inputContainerRef = useRef<HTMLDivElement>(null);
  const [activeIndex, setActiveIndex] = useState<number>(-1);
  const optionRefs = useRef<Array<HTMLButtonElement | null>>([]);

  const theme = useTheme();
  useEffect(() => {
    inputRef?.current?.focus();
  }, []);

  const handleChanges = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setInputValue(value);
    String(value).length > 1 && emitter.emit("search", value);
  };

  const handleClear = () => {
    setInputValue("");
    inputRef?.current?.focus();
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    search && search(inputValue);
  };

  useEffect(() => {
    if (options && options.length > 0) {
      setIsOpen(true);
    }
  }, [options]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        inputContainerRef.current &&
        !inputContainerRef.current.contains(event.target as Node)
      ) {
        setIsFocused(false);
        inputRef.current?.blur();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement | HTMLButtonElement>
  ) => {
    if (!isFocused || !options || !handleOptionClick) return;

    switch (event.key) {
      case "ArrowDown":
        event.preventDefault();
        setActiveIndex(prevIndex =>
          Math.min(prevIndex + 1, options.length - 1)
        );
        break;
      case "ArrowUp":
        event.preventDefault();
        setActiveIndex(prevIndex => Math.max(prevIndex - 1, 0));
        break;
      case "Enter":
        event.preventDefault();
        if (activeIndex >= 0 && activeIndex < options.length) {
          handleOptionClick(options[activeIndex].value);
          setInputValue(options[activeIndex].title);
          setIsFocused(false);
        }
        break;
      case "Escape":
        setIsFocused(false);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (activeIndex >= 0 && options && activeIndex < options.length) {
      optionRefs.current[activeIndex]?.focus();
    }
  }, [activeIndex]);

  return (
    <form
      onSubmit={handleSubmit}
      className={classNames(styles.searchField, {})}
    >
      <div
        className={classNames(styles.inputContainer, {
          [styles.focused]: isFocused,
          [styles.dark]: theme === "dark",
          [styles.wide]: wide,
        })}
        ref={inputContainerRef}
      >
        <label
          className={styles.inputLabel}
          htmlFor="searchField"
          aria-label={label}
        >
          {withIcon && (
            <IconMS
              className={styles.searchIcon}
              name="search"
              size="24px"
              fill
            />
          )}
          <div className={styles.inputField}>
            <input
              id="searchField"
              ref={inputRef}
              type="text"
              value={inputValue}
              onChange={handleChanges}
              placeholder={placeholder}
              onFocus={() => setIsFocused(true)}
              onBlur={() => !options && setIsFocused(false)}
              onKeyDown={handleKeyDown}
            />
            {options && handleOptionClick && isFocused && (
              <div className={styles.dropDownContainer}>
                {options.length > 0 ? (
                  <ul className={styles.dropDown}>
                    {options.map((option, index) => (
                      <button
                        onKeyDown={handleKeyDown}
                        ref={el => (optionRefs.current[index] = el)}
                        className={styles.option}
                        onClick={() => {
                          handleOptionClick(option.value);
                          setInputValue(option.title);
                        }}
                      >
                        <p>{option.title}</p>
                        <IconMS
                          size="20px"
                          className={styles.icon}
                          name="arrow_forward"
                        />
                      </button>
                    ))}
                  </ul>
                ) : isLoading ? (
                  <Spinner className={styles.spinner} />
                ) : (
                  <p className={styles.noHits}>Ingen treff</p>
                )}
              </div>
            )}
          </div>
        </label>
        <button
          className={classNames(styles.clearButton, {
            [styles.showClearButton]: inputValue.length > 0,
          })}
          onClick={handleClear}
          type="button"
        >
          <Icon name="close" />
        </button>
      </div>
      {!withoutButton ? (
        <Button disabled={inputValue.length < 2} className={styles.submit}>
          <IconMS name="search" size="24px" />
          {buttonLabel}
        </Button>
      ) : null}
    </form>
  );
};

export default SearchField;
