import React from "react";
import styles from "./Sort.module.scss";
import { Dropdown } from "SiteComponents";
import { DropdownProps } from "components/base/Dropdown/Dropdown";

interface SortItemParameters {
  displayName: string;
  sortKey: string;
  sortOrder: "asc" | "desc";
}

export interface SortProps extends DropdownProps {
  sortOptionList: SortItemParameters[];
  onChange?: (sortKey: string, sortOrder: string) => void;
}

export const Sort = ({
  buttonLabel,
  labels,
  sortOptionList,
  onChange = () => {},
}: SortProps) => {
  return (
    <div className={styles.sort}>
      <Dropdown buttonLabel={buttonLabel} labels={labels}>
        {sortOptionList.map(option => (
          <li key={option.displayName} role="menuitem">
            <button
              onClick={() => onChange(option.sortKey, option.sortOrder)}
              className={styles.option}
            >
              {option.displayName}
            </button>
          </li>
        ))}
      </Dropdown>
    </div>
  );
};

export default Sort;
