import classNames from "classnames";
import { Button, CtaLinkBlock } from "SiteComponents";
import { LinkProps } from "src/base-props/LinkProps";
import useHeroBackground from "src/utils/use-hero-background/useHeroBackground";
import styles from "./ReportReadPageHeroBlock.module.scss";
import { GlobalCardBlockProps } from "../GlobalCardBlock/GlobalCardBlock";

export interface ReportReadPageHeroBlockProps {
  heading: string;
  reportFrontPageLink?: GlobalCardBlockProps;
  subHeading?: string;
  className?: string;
}

const ReportReadPageHeroBlock = ({
  heading,
  reportFrontPageLink,
  subHeading,
  className,
}: ReportReadPageHeroBlockProps) => {
  const backgroundClass = useHeroBackground();
  return (
    <div
      className={classNames(
        backgroundClass,
        className,
        styles.reportReadPageHeroBlock
      )}
    >
      {reportFrontPageLink && reportFrontPageLink.link && (
        <CtaLinkBlock
          text={reportFrontPageLink.title}
          url={reportFrontPageLink.link.url}
          theme="secondary"
          className={styles.button}
        />
      )}
      <div className={styles.text}>
        {subHeading && <p>{subHeading}</p>}
        {heading && <h1>{heading}</h1>}
      </div>
    </div>
  );
};

export default ReportReadPageHeroBlock;
