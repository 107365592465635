import useHeroBackground from "src/utils/use-hero-background/useHeroBackground";
import styles from "./StatisticsPageHero.module.scss";
import classNames from "classnames";

export interface StatisticsPageHeroProps {
  heading: string;
  ingress: string;
}

const StatisticsPageHero = ({ heading, ingress }: StatisticsPageHeroProps) => {
  const backgroundClass = useHeroBackground();
  return (
    <div className={classNames(styles.statisticsPageHero, backgroundClass)}>
      <div className={styles.content}>
        <h1>{heading}</h1>
        <p>{ingress}</p>
      </div>
    </div>
  );
};

export default StatisticsPageHero;
