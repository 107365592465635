import React from "react";
import { ReactComponentPropsBase } from "../../base-props/ReactComponentPropsBase";
import { HeroBlockProps } from "components/blocks/HeroBlock/HeroBlock";
import ContentArea, {
  ContentAreaProps,
} from "components/utils/ContentArea/ContentArea";

export interface SearchResultPageProps extends ReactComponentPropsBase {
  hero?: HeroBlockProps;
  contentArea: ContentAreaProps;
}

//TODO: Obsolete legacy search. Should be removed after all sites have moved to the new v2 page templates.
const SearchResultPage: React.FC<SearchResultPageProps> = ({
  contentArea,
  hero,
}) => {
  return (
    <main className="searchResultPage">
      {contentArea && <ContentArea {...contentArea} />}
    </main>
  );
};

export default SearchResultPage;
