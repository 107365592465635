import { useEffect } from "react";
import ReactDOM from "react-dom";
import classNames from "classnames";
import { ReactComponentPropsBase } from "../../../base-props/ReactComponentPropsBase";
import "./RichText.scss";
import styles from "./RichText.module.scss";
import useSelectTheme from "../../../utils/useSelectTheme";
import IconMS from "../../utils/IconMS/IconMS";

export interface RichTextProps extends ReactComponentPropsBase {
  html: string;
  className?: string;
  style?: React.CSSProperties;
  propRef?: React.RefObject<HTMLDivElement>;
  textRefFunction?: (element: HTMLDivElement) => void;
}

const RichText: React.FC<RichTextProps> = ({
  html,
  className,
  propRef,
  style,
  textRefFunction,
}) => {
  const selectedTheme = useSelectTheme("", "mangfoldsprisLayout");

  // Function to add icons to external links
  const addExternalLinkIcons = (container: HTMLDivElement | null) => {
    if (!container) return;

    const links = container.querySelectorAll<HTMLAnchorElement>("a");

    links.forEach(link => {
      if (link.target === "_blank" && link.classList.contains("externalLink")) {
        return;
      }

      if (link.target === "_blank") {
        link.classList.add("externalLink");

        const iconContainer = document.createElement("span");

        const iconElement = (
          <IconMS className={styles.linkIcon} name="open_in_new" size="16px" />
        );

        ReactDOM.render(iconElement, iconContainer);

        link.appendChild(iconContainer);
      }
    });
  };

  useEffect(() => {
    const containers = document.querySelectorAll<HTMLDivElement>(
      `.${styles.richText}`
    );

    containers.forEach(container => {
      addExternalLinkIcons(container);
    });
  }, []);

  return (
    <div
      className={classNames(styles.richText, className, styles[selectedTheme])}
      dangerouslySetInnerHTML={{ __html: html }}
      ref={textRefFunction}
      style={style}
    />
  );
};

export default RichText;
