import StatisticsBlock, {
  StatisticsBlockProps,
} from "components/blocks/StatisticsBlock/StatisticsBlock";
import styles from "./StatisticsPage.module.scss";
import { useEffect, useState } from "react";
import emitter from "src/utils/emitter";
import {
  AccordionItemBlock,
  Button,
  ContentArea,
  GlobalLinkListBlock,
  SearchField,
} from "SiteComponents";
import { getAllRegions, getRegions } from "src/utils/simService";
import classNames from "classnames";
import { ContentAreaProps } from "components/utils/ContentArea/ContentArea";
import { LinkProps } from "src/base-props/LinkProps";
import StatisticsPageHero from "components/blocks/StatisticsPageHero/StatisticsPageHero";

interface StatisticsPageProps {
  title: string;
  ingress: string;
  themeFilterList: { title: string; id: string }[];
  theme: ThemeProps;
  subThemeList: ThemeProps[];
  selectedThemeId: string;
  regionCode: string;
  countiesHeading: string;
  numbersForPrefix: string;
  regionPickerText: string;
  themePickerText: string;
  searchPlaceholderText: string;
}

interface Reponse {
  theme: ThemeProps;
  subThemeList: ThemeProps[];
}

interface ThemeProps {
  title: string;
  ingress: ContentAreaProps;
  statisticsBlockList: StatisticsBlockProps[];
}

interface Params {
  regionCode?: string;
  themeId?: string;
}

const StatisticsPage = ({
  title,
  ingress,
  themeFilterList,
  theme,
  subThemeList,
  regionCode,
  selectedThemeId,
  countiesHeading,
  numbersForPrefix,
  regionPickerText,
  themePickerText,
  searchPlaceholderText,
}: StatisticsPageProps) => {
  const [regions, setRegions] = useState<{ title: string; value: string }[]>();
  const [isLoading, setIsLoading] = useState(false);
  const [allRegions, setAllRegions] =
    useState<{ title: string; value: string }[]>();

  const handleClick = (id: string) => {
    updateQueryParam("themeId", id);
    window.location.reload();
  };

  const handleRegionChange = (value: string) => {
    updateQueryParam("regionCode", value);
    window.location.reload();
  };

  const updateQueryParam = (key: string, value: string) => {
    const url = new URL(window.location.href);
    const searchParams = url.searchParams;

    searchParams.set(key, value);
    url.search = searchParams.toString();

    window.history.pushState(null, "", url.toString());
  };

  const getFylker = (
    regions: { title: string; value: string }[]
  ): LinkProps[] => {
    return regions
      .filter(
        region =>
          region.value.startsWith("F") &&
          region.value !== "F00" &&
          region.value !== "F99"
      )
      .map(fylke => {
        const url = new URL(window.location.href);
        const searchParams = url.searchParams;
        searchParams.set("regionCode", fylke.value);
        url.search = searchParams.toString();
        return {
          text: fylke.title.slice(0, fylke.title.length - 5),
          url: url.toString(),
        };
      });
  };

  const handleSearchEvent = (query: string) => {
    setIsLoading(true);
    getRegions(query).then(res => {
      var regions = res.map(result => {
        return { title: result.title, value: result.label };
      });
      setRegions(regions);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    getAllRegions().then(res => {
      var regions = res.map(result => {
        return { title: result.display_name, value: result.label };
      });
      setAllRegions(regions);
    });
    updateQueryParam("regionCode", regionCode);
    updateQueryParam("themeId", selectedThemeId);
    emitter.on("search", handleSearchEvent);

    return () => emitter.off("search", handleSearchEvent);
  }, []);

  return (
    <div>
      <StatisticsPageHero heading={title} ingress={ingress} />
      <div className={styles.statisticsPage}>
        <div className={classNames(styles.main, styles.search)}>
          <p>{regionPickerText}</p>
          <SearchField
            options={regions}
            handleOptionClick={handleRegionChange}
            isLoading={isLoading}
            placeholder={searchPlaceholderText}
            wide
            withoutButton
            withIcon
          />
        </div>
        <div className={classNames(styles.main, styles.themePicker)}>
          {allRegions && (
            <h2>
              {numbersForPrefix}{" "}
              {allRegions.find(region => region.value === regionCode)?.title}
            </h2>
          )}
          <p>{themePickerText}</p>
          <ul className={styles.themeList}>
            {themeFilterList &&
              themeFilterList.map(theme => (
                <Button
                  className={styles.button}
                  onClick={() => handleClick(theme.id)}
                  theme={theme.id === selectedThemeId ? "primary" : "secondary"}
                >
                  {theme.title}
                </Button>
              ))}
          </ul>
        </div>
        {theme && (
          <div className={classNames(styles.main, styles.theme)}>
            <h2>{theme.title}</h2>
            {theme.ingress && <ContentArea {...theme.ingress} noGrid />}
            <div className={classNames(styles.statisticsBlocks)}>
              {theme.statisticsBlockList.filter(block => !block.showAsGraph)
                .length > 0 && (
                <div className={styles.keyNumbers}>
                  {theme.statisticsBlockList.map(
                    block =>
                      !block.showAsGraph && <StatisticsBlock {...block} />
                  )}
                </div>
              )}
              {theme.statisticsBlockList.length > 0 &&
                theme.statisticsBlockList.map(
                  block =>
                    block.showAsGraph && (
                      <AccordionItemBlock
                        heading={block.displayName ?? block.cardId}
                        statistics={block}
                      />
                    )
                )}
            </div>
          </div>
        )}
        {subThemeList &&
          subThemeList.map(theme => (
            <div className={classNames(styles.main, styles.subTheme)}>
              <h3>{theme.title}</h3>
              {theme.ingress && <ContentArea {...theme.ingress} noGrid />}
              <div className={styles.statisticsBlocks}>
                {theme.statisticsBlockList.filter(block => !block.showAsGraph)
                  .length > 0 && (
                  <div className={styles.keyNumbers}>
                    {theme.statisticsBlockList.map(
                      block =>
                        !block.showAsGraph && <StatisticsBlock {...block} />
                    )}
                  </div>
                )}
                {theme.statisticsBlockList.length > 0 &&
                  theme.statisticsBlockList.map(
                    block =>
                      block.showAsGraph && (
                        <AccordionItemBlock
                          heading={block.cardId}
                          statistics={block}
                        />
                      )
                  )}
              </div>
            </div>
          ))}
        <div className={styles.fullWidth}>
          {allRegions && (
            <GlobalLinkListBlock
              heading={countiesHeading}
              linkListItems={getFylker(allRegions)}
              threeColumn
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default StatisticsPage;
